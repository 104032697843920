import {
  cloneElement,
  default as React,
  FunctionComponent,
  ReactElement,
  useCallback,
  useState,
} from 'react'
import { useDropzone } from 'react-dropzone'
import cn from 'classnames'
import useThunkDispatch from '../common/useThunkDispatch'
import { isNumber, reduce } from 'lodash/fp'
import Button from './Button'
import ModalWrapper from './Modal/ModalWrapper'
import LoadingIcon from '../icons/loading.svg'
import OkIcon from '../icons/ok_cirlce.svg'
import WrongIcon from '../icons/cross_circle.svg'
import { ThunkAction } from '../types/common'

const cReduce = (reduce as any).convert({ cap: false })

export const Dropzone: FunctionComponent<{
  onDrop: (files: File[]) => void
  filename?: string
}> = ({ onDrop, filename }) => {
  const onDropRejected = () => alert('File is too big, max size is 5mb')

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize: 512000,
    onDropRejected,
  })
  const [over, setOver] = useState(false)

  const text = () => {
    if (over) return 'Ok, now drop'
    if (filename) return filename

    return (
      <>
        Drag and drop the file here <br />
        or click to select on computer.
        <br />
        <br />
        Max size: 5mb
      </>
    )
  }

  return (
    <label
      className={cn('import_dropzone', {
        '-over': over,
        '-selected': filename,
      })}
      {...getRootProps({
        onDragEnter: () => setOver(true),
        onDragLeave: () => setOver(false),
        onDrop: () => setOver(false),
      })}
    >
      <div className="import_dropzone-text">{text()}</div>
      <input
        type="file"
        {...getInputProps({ multiple: false })}
        className="import_dropzone-input"
      />
    </label>
  )
}

interface IOwnProps {
  close: (satus?: string) => void
  action: (
    file: File
  ) => ThunkAction<Promise<number | { [rowNum: string]: string[] }>>
  title: string
  templateUrl?: string
  templateName?: string
}

export const TriggerImportDialog: FunctionComponent<
  { triggerElement: ReactElement } & Omit<IOwnProps, 'close'>
> = ({ triggerElement, ...dialogProps }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      {cloneElement(triggerElement, { onClick: () => setOpen(true) })}
      {open && <ImportDialog {...dialogProps} close={() => setOpen(false)} />}
    </>
  )
}

const ImportDialog: FunctionComponent<IOwnProps> = ({
  close,
  action,
  templateName,
  templateUrl,
  title,
}) => {
  const dispatch = useThunkDispatch()

  const [status, setStatus] = useState<
    'ready' | 'uploading' | 'error' | 'done'
  >('ready')

  const [errorUrl, setErrorUrl] = useState<string | undefined>(undefined)
  const [updatedCount, setUpdatedCount] = useState(0)

  const [file, setFile] = useState<File | undefined>(undefined)
  const onDrop = useCallback(acceptedFiles => {
    setFile(acceptedFiles[0])
  }, [])

  const onSubmit = async () => {
    if (!file) return

    try {
      setStatus('uploading')
      const errorsOrCount = await dispatch(action(file))

      if (isNumber(errorsOrCount)) {
        setUpdatedCount(errorsOrCount)
        setStatus('done')
      } else {
        const errFile = new Blob(
          [
            cReduce(
              (text: string, messages: string[], key: string) =>
                `${text}\n${key}: ${messages.join(', ')}`,
              '',
              errorsOrCount
            ),
          ],
          { type: 'text/plain' }
        )
        setErrorUrl(URL.createObjectURL(errFile))
        setStatus('error')
      }
    } catch (e) {
      setStatus('error')
    }
  }

  const onClose = () => {
    reset()
    close(status)
  }

  const reset = () => {
    setStatus('ready')
    setFile(undefined)
    setErrorUrl(undefined)
    setUpdatedCount(0)
  }

  return (
    <ModalWrapper className="import">
      <button className="import_backdrop clear-button" onClick={onClose} />

      <div className="import_dialog">
        {status !== 'uploading' && (
          <button
            className="import_close clear-button link"
            onClick={onClose}
          />
        )}

        {/* DEFAULT STATE */}
        {status === 'ready' && (
          <>
            <div className="import_title">{title}</div>
            <div className="import_note">⚠️ Importing products is for the Forshaw catalog only!</div>

            <Dropzone onDrop={onDrop} filename={file && file.name} />

            <Button disabled={!file} block onClick={onSubmit}>
              START IMPORT
            </Button>
            {templateUrl && (
              <a
                href={templateUrl}
                download={templateName}
                className="import_download primary-link"
              >
                Download import template
              </a>
            )}
          </>
        )}

        {/* WHEN LIST IS UPLOADING */}
        {status === 'uploading' && (
          <>
            <div className="import-icon">
              <LoadingIcon width={70} height={70} />
            </div>
            <div className="import_info-title">Importing</div>
            <div className="import_info-text">Just a moment</div>
          </>
        )}

        {/* WHEN LIST IS SUCCESSFULLY UPLOADED */}
        {status === 'done' && (
          <>
            <div className="import-icon -ok">
              <OkIcon width={50} height={50} />
            </div>
            <div className="import_info-title">
              {updatedCount} records has been imported
            </div>
            <div className="import_info-text">
              Close the window and check results
            </div>
            <Button
              block
              color="primary-link"
              onClick={onClose}
              className="import_info-button"
            >
              Done
            </Button>
          </>
        )}

        {/* WHEN LIST IS UPLOADED WITH ERRORS */}
        {status === 'error' && (
          <>
            <div className="import-icon -wrong">
              <WrongIcon width={50} height={50} />
            </div>
            <div className="import_info-title">Something went wrong</div>
            {errorUrl && (
              <div className="import_info-text">
                Check{' '}
                <a href={errorUrl} download="import_errors.txt">
                  errors file
                </a>{' '}
                and try again
              </div>
            )}
            <Button
              block
              color="primary-link"
              onClick={reset}
              className="import_info-button"
            >
              Try Again
            </Button>
          </>
        )}
      </div>
    </ModalWrapper>
  )
}

export default ImportDialog
